import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import LogoVectorLight from "../../assets/images/logo/logoSignPunsLight.svg";
import LogoVectorDark from "../../assets/images/logo/logoSignPunsDark.svg";
import { LogoWrapper } from './logo.style';

const Logo = props => {
    return (
        <LogoWrapper {...props}>
            <Link to="/">
                <img src={LogoVectorLight} className="img-fluid light-logo" alt="logo" />
                <img src={LogoVectorDark} className="img-fluid dark-logo" alt="logo" />
            </Link>
        </LogoWrapper>
    )
}

Logo.propTypes = {
    justifycontent: PropTypes.string
}

Logo.defaultProps = {
    justifycontent: 'flex-start'
}

export default Logo
